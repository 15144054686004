import { cva } from 'class-variance-authority';
import { forwardRef } from 'react';
import { Icon } from '~/components/granular';
import { classNames } from '~/utils/styles';
import type { InputProps } from './Input.types';

const inputVariants = cva(
  'body-200-light w-full min-w-40 border border-neutral-400 border-solid px-2 py-2 hover:border-teal-700 focus:border-teal-800 focus:outline-1 focus:outline-teal-700 disabled:cursor-not-allowed disabled:border-neutral-300 disabled:bg-neutral-200 disabled:text-neutral-600',
  {
    variants: {
      appearance: {
        default: 'text-neutral-1000 placeholder:text-neutral-600 focus:outline',
        filter: 'cursor-pointer rounded text-sm leading-4',
      },
      height: {
        small: 'h-8',
        medium: 'h-9',
        large: 'h-10',
      },
    },
    defaultVariants: {
      appearance: 'default',
      height: 'medium',
    },
  },
);

const translateXClasses = 'absolute top-1/2 -translate-y-1/2 transform text-neutral-600 cursor-default body-400-light';

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      appearance = 'default',
      appendRight,
      className,
      disabled,
      hasError,
      height = 'medium',
      id,
      leftIcon,
      placeholder,
      rightIcon,
      showSelectedState = true,
      type = 'text',
      value,
      ...props
    },
    forwardedRef,
  ) => {
    const selectedStateEnabled = showSelectedState && !!value && appearance === 'filter';

    const classes = classNames(
      inputVariants({ appearance, height }),
      {
        'border-red-800 hover:border-red-800 focus:border-red-800 focus:outline-red-800': hasError,
        'pl-7': leftIcon,
        'pr-7': rightIcon || appendRight,
        'border-teal-500 bg-teal-100 text-teal-700': selectedStateEnabled,
      },
      className,
    );

    return (
      <div className="relative">
        {leftIcon && (
          <Icon
            icon={leftIcon}
            role="img"
            className={classNames(translateXClasses, 'left-2', {
              'cursor-not-allowed': disabled,
              'text-teal-700': selectedStateEnabled,
            })}
            height={16}
            width={16}
          />
        )}
        <input
          ref={forwardedRef}
          id={id}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          className={classes}
          value={value}
          {...props}
        />
        {appendRight && <div className={classNames(translateXClasses, 'right-2')}>{appendRight}</div>}
        {rightIcon && (
          <Icon
            icon={rightIcon}
            role="img"
            className={classNames(translateXClasses, 'right-2', {
              'cursor-not-allowed': disabled,
              'text-teal-700': selectedStateEnabled,
            })}
            height={16}
            width={16}
          />
        )}
      </div>
    );
  },
);
