import { type PropsWithChildren, useMemo, useRef } from 'react';
import { useElementSize } from '~/hooks/useElementSize';
import { classNames } from '~/utils';

type GradientScrollerProps = PropsWithChildren<{ className?: string }>;

export function GradientScroller({ children, className }: GradientScrollerProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollerRef = useRef<HTMLDivElement>(null);

  const { height: containerHeight } = useElementSize(containerRef);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Check whenever container size changes
  const showGradient = useMemo(
    () => (scrollerRef.current ? scrollerRef.current.scrollHeight > scrollerRef.current.clientHeight : false),
    [containerHeight],
  );

  return (
    <div className={classNames('relative', className)} ref={containerRef}>
      <div className="h-full overflow-y-auto" ref={scrollerRef}>
        {children}
      </div>

      <div
        aria-hidden
        className={classNames(
          'absolute-fill pointer-events-none',
          'before:absolute before:top-0 before:left-0 before:h-3 before:w-full before:bg-gradient-to-b before:from-white',
          'after:absolute after:bottom-0 after:left-0 after:h-3 after:w-full after:bg-gradient-to-t after:from-white',
          { hidden: !showGradient },
        )}
      />
    </div>
  );
}
