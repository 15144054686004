import { createElement, useCallback } from 'react';
import { FixedSizeList as List } from 'react-window';
import type { VirtualisedListProps } from './VirtualisedList.props';

export function VirtualisedList<T>({
  height = 300,
  itemCount = 0,
  itemSize = 40,
  width = 300,
  data = [],
  children,
}: VirtualisedListProps<T>) {
  const calculateHeight = useCallback(() => Math.min(itemCount * itemSize, height), [height, itemCount, itemSize]);
  return (
    <List height={calculateHeight()} itemCount={itemCount} itemSize={itemSize} width={width} itemData={data}>
      {({ data, style, index }) => createElement(children, { data, style, index })}
    </List>
  );
}
