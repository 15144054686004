import { forwardRef } from 'react';
import type { HighlightedTextProps } from './HighlightedText.types';

export const HighlightedText = forwardRef<HTMLSpanElement, HighlightedTextProps>(
  ({ text, searchValue }, forwardedRef) => {
    if (!searchValue.length) {
      return text;
    }

    const regex = new RegExp(`(${searchValue})`, 'gi');
    const splitTextArr = text.split(regex);

    if (!splitTextArr.length) {
      return text;
    }

    return (
      <span ref={forwardedRef} className="inline">
        {splitTextArr.map((chunk, index) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: there are no unique IDs to use as key, and the array is static between renderings
          <span key={index}>
            {chunk.toLowerCase() === searchValue.toLowerCase() ? <span className="bg-teal-500">{chunk}</span> : chunk}
          </span>
        ))}
      </span>
    );
  },
);
