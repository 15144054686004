import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { forwardRef } from 'react';

import { Icon, Label } from '~/components/granular';
import { classNames } from '~/utils/styles';
import type { CheckboxProps } from './Checkbox.types';

export const Checkbox = forwardRef<HTMLDivElement, CheckboxProps>(
  ({ id, checked, className, disabled, label, appearance = 'light', ...props }, forwardedRef) => {
    const classes = classNames(
      'm-0.5 flex h-max min-h-4 w-max min-w-4 rounded-[1px] border disabled:border-neutral-400',
      {
        'border-teal-700 bg-teal-700 disabled:bg-neutral-400': checked,
      },
    );

    const checkedIcon = checked === 'indeterminate' ? 'SubtractIcon' : 'CheckIcon';

    return (
      <div ref={forwardedRef} className={classNames('flex', className)}>
        <RadixCheckbox.Root id={id} className={classes} checked={checked} disabled={disabled} {...props}>
          <RadixCheckbox.Indicator>
            {checked && <Icon icon={checkedIcon} className="text-white" />}
          </RadixCheckbox.Indicator>
        </RadixCheckbox.Root>

        {label && (
          <Label appearance={appearance} disabled={disabled} htmlFor={id}>
            {label}
          </Label>
        )}
      </div>
    );
  },
);
