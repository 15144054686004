import { forwardRef } from 'react';
import { Card, Icon, Link } from '~/components/granular';
import { classNames } from '~/utils/styles';

import type { CardLinkProps } from './CardLink.types';

export const CardLink = forwardRef<HTMLDivElement, CardLinkProps>(
  ({ to, children, className, icon, title, description, ...props }, forwardedRef) => {
    return (
      <div ref={forwardedRef} className={classNames(className)} data-testid="card-link">
        <Link appearance="none" onClick={to}>
          <Card isLink={!!to} className="flex items-start gap-6 px-6 py-8" {...props}>
            <span className="rounded-full bg-teal-200 p-3">
              <Icon icon={icon} className="text-teal-700" />
            </span>
            {children ? (
              children
            ) : (
              <div className="flex w-full flex-col gap-1">
                <p className="body-100-heavy">{title}</p>
                <div className="body-200-light text-neutral-700">{description}</div>
              </div>
            )}
          </Card>
        </Link>
      </div>
    );
  },
);
