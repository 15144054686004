import ReactLoadingSkeleton from 'react-loading-skeleton';
import { classNames } from '~/utils/styles';

import { Separator } from '~/components/granular/separator';
import type { TabsSkeletonProps } from './TabsSkeleton.types';

export const TabsSkeleton = ({ count, staticItemsCount, ...props }: TabsSkeletonProps) => {
  const items = Array.from({ length: count ?? 5 }, (_, i) => i);
  const staticItems = Array.from({ length: staticItemsCount ?? 1 }, (_, i) => i);
  return (
    <div className="flex border-neutral-300 border-b" data-testid="skeleton-tabs" {...props}>
      {items.map((i) => (
        <TabSkeleton key={`skeleton-tab-${i}`} />
      ))}
      <div className="py-2">
        <Separator orientation="vertical" />
      </div>
      {staticItems.map((i) => (
        <TabSkeleton key={`skeleton-static-tab-${i}`} />
      ))}
    </div>
  );
};

const TabSkeleton = () => {
  const classes = classNames('px-5 py-2');
  return (
    <div className={classes}>
      <ReactLoadingSkeleton width={70} height={16} borderRadius={1} />
    </div>
  );
};
