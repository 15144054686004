import type { CSSProperties, ReactNode } from 'react';
import { type SelectGroup, type SelectItem, useBaseSelectContext } from '~/components/granular';
import { VirtualisedList } from '~/components/granular/virtualised-list';

export function VirtualisedSelectList({
  items,
  children,
}: {
  items?: Array<SelectItem | SelectGroup>;
  children: ({
    data,
    style,
    index,
  }: { data: Array<SelectItem | SelectGroup>; style: CSSProperties; index: number }) => ReactNode;
}) {
  const { items: contextItems, width } = useBaseSelectContext();
  const displayItems = items ?? contextItems;

  const itemCount = displayItems.length;

  return (
    <VirtualisedList
      data={displayItems}
      itemSize={32}
      width={width}
      height={itemCount ? 300 : 0}
      itemCount={itemCount ?? 0}
    >
      {children}
    </VirtualisedList>
  );
}
