import * as React from 'react';
import { colors } from '~/static/colors';
import type { SVGIconProps } from './Icon.types';

const MapPin = React.forwardRef<SVGSVGElement, SVGIconProps>(
  ({ color = 'currentColor', filled, ...props }, forwardedRef) => {
    return (
      <svg
        ref={forwardedRef}
        width="25"
        height="29"
        viewBox="0 0 37 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>{props.title}</title>
        <path
          d="M27.2854 26.152L18.8001 34.6373L10.3147 26.152C8.63651 24.4738 7.49363 22.3356 7.03062 20.0078C6.56761 17.68 6.80526 15.2672 7.71351 13.0745C8.62177 10.8818 10.1598 9.0077 12.1332 7.68913C14.1066 6.37056 16.4267 5.66678 18.8001 5.66678C21.1734 5.66678 23.4935 6.37056 25.4669 7.68913C27.4403 9.0077 28.9783 10.8818 29.8866 13.0745C30.7948 15.2672 31.0325 17.68 30.5695 20.0078C30.1065 22.3356 28.9636 24.4738 27.2854 26.152Z"
          fill={color}
          stroke={colors.neutral[1000]}
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </svg>
    );
  },
);

export default MapPin;
