import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { type Ref, forwardRef } from 'react';

import { classNames } from '~/utils/styles';

import type { RadioGroupProps } from './RadioGroup.types';
import { Item } from './components';

export const RadioGroup = forwardRef(
  (
    {
      className,
      items,
      value,
      defaultValue,
      ariaLabel,
      appearance = 'light',
      disabled,
      onValueChange,
      ...props
    }: RadioGroupProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const classes = classNames('flex flex-col gap-5', className);

    return (
      <RadixRadioGroup.Root
        ref={ref}
        className={classes}
        defaultValue={defaultValue}
        aria-label={ariaLabel}
        value={value}
        orientation="vertical"
        disabled={disabled}
        onValueChange={onValueChange}
        {...props}
      >
        {items.map((item) => (
          <Item
            key={item.id}
            id={item.id}
            label={item.label}
            value={item.value}
            appearance={appearance}
            disabled={item.disabled || disabled}
          />
        ))}
      </RadixRadioGroup.Root>
    );
  },
);
