import { forwardRef } from 'react';

import * as Icons from '~/components/icons';

import type { IconProps } from './Icon.types';

export const Icon = forwardRef<SVGSVGElement, IconProps>(({ icon, ...props }, forwardedRef) => {
  const Icon = Icons[icon];

  return <Icon data-testid={icon} ref={forwardedRef} {...props} />;
});
