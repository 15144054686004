import { type ElementType, type RefObject, forwardRef } from 'react';

import { Icon } from '~/components/granular';
import { classNames } from '~/utils/styles';

import type { LinkProps } from './Link.types';

type RefType = RefObject<HTMLButtonElement> | RefObject<HTMLAnchorElement>;

export const Link = forwardRef<RefType, LinkProps>(
  (
    { as = 'a', appearance = 'default', leftIcon, rightIcon, className, children, size = 'md', ...props },
    forwardedRef,
  ) => {
    const classes = classNames(
      'cursor-pointer items-center disabled:cursor-not-allowed disabled:text-neutral-600',
      {
        'text-teal-700 hover:text-teal-800 focus:bg-teal-200 active:text-teal-900': appearance === 'default',
        'text-neutral-1000 hover:text-neutral-1000 focus:bg-teal-200 active:text-teal-900': appearance === 'secondary',
        'text-red-700 hover:text-red-800 focus:bg-red-200 active:text-red-900': appearance === 'danger',
        'text-white hover:text-teal-200 focus:text-teal-200 active:text-teal-200': appearance === 'white',
        'inline-flex': as !== 'button' && appearance !== 'none',
        'label-200': as !== 'button' && size === 'sm',
        'link-100': as !== 'button' && size === 'lg',
        'link-200': as !== 'button' && size === 'md',
        'button-200 flex': as === 'button' && appearance !== 'none',
        '!no-underline': appearance === 'none',
      },
      className,
    );

    const Element = as as ElementType;

    return (
      <Element ref={forwardedRef} className={classes} role={as === 'a' ? 'link' : 'button'} {...props}>
        {leftIcon && <Icon icon={leftIcon} className="inline-block pr-1" />}
        {children}
        {rightIcon && <Icon icon={rightIcon} className="inline-block pl-1" />}
      </Element>
    );
  },
);
