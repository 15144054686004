import * as RadixSwitch from '@radix-ui/react-switch';
import { forwardRef } from 'react';

import { Label } from '~/components/granular';
import { classNames } from '~/utils/styles';

import type { SwitchProps } from './Switch.types';

export const Switch = forwardRef<HTMLDivElement, SwitchProps>(
  ({ className, id, checked, disabled, label, description, appearance = 'light', ...props }, forwardedRef) => {
    const background = disabled ? 'bg-neutral-400' : checked ? 'bg-teal-700' : 'bg-neutral-600';
    const animation =
      'transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[16px]';
    const descriptionColor = disabled ? 'text-neutral-500' : 'text-neutral-800';

    return (
      <div ref={forwardedRef} className={classNames('flex', className)}>
        <RadixSwitch.Root
          id={id}
          checked={checked}
          disabled={disabled}
          className={classNames('my-px h-[18px] w-8 shrink-0 rounded-full', background)}
          {...props}
        >
          <RadixSwitch.Thumb
            className={classNames(
              'my-0.5 block h-[14px] w-[14px] rounded-full bg-white data-[disabled]:bg-neutral-100',
              animation,
            )}
          />
        </RadixSwitch.Root>

        {(label || description) && (
          <div className="flex flex-col">
            <Label appearance={appearance} disabled={disabled} htmlFor={id} className="cursor-default">
              {label}
            </Label>
            {description && (
              <div className={classNames('body-300-light mt-0.5 ml-2', descriptionColor)}>{description}</div>
            )}
          </div>
        )}
      </div>
    );
  },
);
