import * as React from 'react';
import type { SVGIconProps } from './Icon.types';

const GranularLogo = React.forwardRef<SVGSVGElement, SVGIconProps>(
  ({ color = 'currentColor', filled, ...props }, forwardedRef) => {
    return (
      <svg
        ref={forwardedRef}
        width="127"
        height="24"
        viewBox="0 0 127 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>{props.title}</title>
        <g clipPath="url(#clip0_2158_1421)">
          <path
            d="M7.27225 13.5228V21.6595C7.27225 22.6096 6.65789 23.2168 5.73636 23.2168H0.700079V21.7666H5.12915C5.37918 21.7666 5.52205 21.6237 5.52205 21.3523V19.3163H5.37918C4.98628 19.8593 4.32192 20.4022 3.12178 20.4022C1.47874 20.4022 0 19.0092 0 16.8661C0 14.723 1.47874 13.33 3.12178 13.33C4.32192 13.33 4.98628 13.8586 5.40775 14.4587H5.54348V13.5228H7.27225ZM5.54348 16.8661C5.54348 15.6159 4.73625 14.8301 3.65041 14.8301C2.56457 14.8301 1.75734 15.6088 1.75734 16.8661C1.75734 18.1234 2.56457 18.902 3.65041 18.902C4.73625 18.902 5.54348 18.1234 5.54348 16.8661Z"
            fill="#2A2929"
          />
          <path
            d="M10.5583 14.4944H10.694C10.9583 13.7586 11.5441 13.4943 12.4228 13.4943H13.1443V14.973H12.1156C11.2227 14.973 10.5797 15.4445 10.5797 16.4232V20.4379H8.82239V13.53H10.5512V14.5015L10.5583 14.4944Z"
            fill="#2A2929"
          />
          <path
            d="M18.9593 20.4306V19.4948H18.8235C18.4092 20.0948 17.7377 20.6235 16.5376 20.6235C14.8945 20.6235 13.4158 19.2304 13.4158 16.973C13.4158 14.7156 14.8945 13.3226 16.5376 13.3226C17.7377 13.3226 18.402 13.8513 18.8235 14.4513H18.9593V13.5155H20.688V20.4234H18.9593V20.4306ZM18.9593 16.973C18.9593 15.6086 18.152 14.8299 17.0662 14.8299C15.9803 14.8299 15.1731 15.6086 15.1731 16.973C15.1731 18.3375 15.9803 19.1161 17.0662 19.1161C18.152 19.1161 18.9593 18.3375 18.9593 16.973Z"
            fill="#2A2929"
          />
          <path
            d="M23.9741 13.5229V14.5516H24.1099C24.3885 13.9658 24.9742 13.4086 26.2387 13.4086C27.8531 13.4086 28.9247 14.5944 28.9247 16.3089V20.4308H27.1673V16.3875C27.1673 15.3445 26.6387 14.8302 25.6886 14.8302C24.6171 14.8302 23.9884 15.5802 23.9884 16.8661V20.4308H22.2311V13.5229H23.9598H23.9741Z"
            fill="#2A2929"
          />
          <path
            d="M35.3397 20.4306V19.4019H35.204C34.9254 19.9877 34.3396 20.5449 33.0752 20.5449C31.4607 20.5449 30.3892 19.359 30.3892 17.6445V13.5226H32.1465V17.5588C32.1465 18.6018 32.6751 19.1161 33.6252 19.1161C34.6968 19.1161 35.3254 18.366 35.3254 17.0802V13.5155H37.0828V20.4234H35.354L35.3397 20.4306Z"
            fill="#2A2929"
          />
          <path d="M40.3831 20.4308H38.6257V10.6797H40.3831V20.4308Z" fill="#2A2929" />
          <path
            d="M47.0695 20.4306V19.4948H46.9267C46.5123 20.0948 45.8408 20.6235 44.6407 20.6235C42.9977 20.6235 41.5189 19.2304 41.5189 16.973C41.5189 14.7156 42.9977 13.3226 44.6407 13.3226C45.8408 13.3226 46.5052 13.8513 46.9267 14.4513H47.0695V13.5155H48.7983V20.4234H47.0695V20.4306ZM47.0695 16.973C47.0695 15.6086 46.2623 14.8299 45.1765 14.8299C44.0906 14.8299 43.2834 15.6086 43.2834 16.973C43.2834 18.3375 44.0906 19.1161 45.1765 19.1161C46.2623 19.1161 47.0695 18.3375 47.0695 16.973Z"
            fill="#2A2929"
          />
          <path
            d="M52.0844 14.4944H52.2201C52.4845 13.7586 53.0702 13.4943 53.9489 13.4943H54.6704V14.973H53.6417C52.7488 14.973 52.1059 15.4445 52.1059 16.4232V20.4379H50.3485V13.53H52.0773V14.5015L52.0844 14.4944Z"
            fill="#2A2929"
          />
          <path
            d="M62.3785 13.3226C64.3859 13.3226 65.736 14.7299 65.736 16.8587V17.516H60.7069C60.7783 18.4089 61.4855 19.1161 62.4642 19.1161C63.4429 19.1161 63.9287 18.5732 64.2216 18.0589L65.6289 18.9233C65.236 19.4519 64.4144 20.6235 62.4642 20.6235C60.3497 20.6235 58.9138 19.0661 58.9138 16.9159C58.9138 14.7657 60.3497 13.3226 62.3856 13.3226H62.3785ZM63.9501 16.2873C63.8501 15.38 63.2643 14.8228 62.3642 14.8228C61.3784 14.8228 60.8283 15.4086 60.7212 16.2873H63.9501Z"
            fill="#2A2929"
          />
          <path
            d="M68.5506 13.5229V14.5516H68.6863C68.9649 13.9658 69.5507 13.4086 70.8151 13.4086C72.4296 13.4086 73.5011 14.5944 73.5011 16.3089V20.4308H71.7438V16.3875C71.7438 15.3445 71.2151 14.8302 70.265 14.8302C69.1935 14.8302 68.5648 15.5802 68.5648 16.8661V20.4308H66.8075V13.5229H68.5363H68.5506Z"
            fill="#2A2929"
          />
          <path
            d="M78.0374 13.3226C80.0448 13.3226 81.395 14.7299 81.395 16.8587V17.516H76.3658C76.4373 18.4089 77.1445 19.1161 78.1232 19.1161C79.1018 19.1161 79.5876 18.5732 79.8805 18.0589L81.2878 18.9233C80.8949 19.4519 80.0734 20.6235 78.1232 20.6235C76.0086 20.6235 74.5728 19.0661 74.5728 16.9159C74.5728 14.7657 76.0086 13.3226 78.0446 13.3226H78.0374ZM79.609 16.2873C79.509 15.38 78.9232 14.8228 78.0231 14.8228C77.0373 14.8228 76.4873 15.4086 76.3801 16.2873H79.609Z"
            fill="#2A2929"
          />
          <path
            d="M84.2095 14.4944H84.3453C84.6096 13.7586 85.1954 13.4943 86.074 13.4943H86.7955V14.973H85.7669C84.8739 14.973 84.231 15.4445 84.231 16.4232V20.4379H82.4736V13.53H84.2024V14.5015L84.2095 14.4944Z"
            fill="#2A2929"
          />
          <path
            d="M94.332 13.5228V21.6595C94.332 22.6096 93.7176 23.2168 92.7961 23.2168H87.7527V21.7666H92.1818C92.4318 21.7666 92.5747 21.6237 92.5747 21.3523V19.3163H92.4318C92.0389 19.8593 91.3745 20.4022 90.1744 20.4022C88.5314 20.4022 87.0526 19.0092 87.0526 16.8661C87.0526 14.723 88.5314 13.33 90.1744 13.33C91.3745 13.33 92.0389 13.8586 92.4604 14.4587H92.5961V13.5228H94.3249H94.332ZM92.6032 16.8661C92.6032 15.6159 91.796 14.8301 90.7102 14.8301C89.6243 14.8301 88.8171 15.6088 88.8171 16.8661C88.8171 18.1234 89.6243 18.902 90.7102 18.902C91.796 18.902 92.6032 18.1234 92.6032 16.8661Z"
            fill="#2A2929"
          />
          <path
            d="M102.719 13.5226L98.9326 23.2166H97.0396L98.0182 20.8735L95.1465 13.5155H97.0396L98.8612 18.5018H99.0041L100.826 13.5155H102.719V13.5226Z"
            fill="#2A2929"
          />
          <path
            d="M103.569 2.84298C102.226 0.514142 99.4398 -0.557407 96.8823 0.278402L78.6946 6.18621C77.9945 6.41481 78.1588 7.44349 78.8946 7.44349H106.226L103.569 2.84298Z"
            fill="#2A2929"
          />
          <path
            d="M126.357 9.16547H107.219L109.884 13.7874C111.17 16.0162 113.97 16.8449 116.263 15.6733L126.643 10.3799C127.243 10.0727 127.029 9.15833 126.35 9.15833L126.357 9.16547Z"
            fill="#2A2929"
          />
        </g>
        <defs>
          <clipPath id="clip0_2158_1421">
            <rect width="127" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

export default GranularLogo;
