import { format } from 'date-fns';
import { forwardRef } from 'react';

import { DATE_FORMATS } from '~/utils/date';

import { Icon } from '../../icon';
import type { DatePickerHeaderProps } from './';

export const DatePickerHeader = forwardRef<HTMLDivElement, DatePickerHeaderProps>(
  (
    {
      monthDate,
      decreaseMonth,
      increaseMonth,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled,
      decreaseYear,
      increaseYear,
      prevYearButtonDisabled,
      nextYearButtonDisabled,
      showMonthYearPicker,
    },
    forwardedRef,
  ) => {
    const decrease = showMonthYearPicker ? decreaseYear : decreaseMonth;
    const decreaseButtonDisabled = showMonthYearPicker ? prevYearButtonDisabled : prevMonthButtonDisabled;
    const increase = showMonthYearPicker ? increaseYear : increaseMonth;
    const increaseButtonDisabled = showMonthYearPicker ? nextYearButtonDisabled : nextMonthButtonDisabled;
    return (
      // biome-ignore lint/a11y/useSemanticElements: TODO: add correct heading element and style.
      <div ref={forwardedRef} className="flex justify-between" role="heading" aria-level={6}>
        <button
          type="button"
          onClick={decrease}
          disabled={decreaseButtonDisabled}
          aria-label="previous"
          className="text-neutral-600"
        >
          <Icon icon="ArrowLeftSIcon" height="20" width="20" />
        </button>

        <div className="body-200-heavy">
          {format(monthDate, showMonthYearPicker ? DATE_FORMATS.YEAR : DATE_FORMATS.MONTH_YEAR)}
        </div>

        <button
          type="button"
          onClick={increase}
          disabled={increaseButtonDisabled}
          aria-label="next"
          className="text-neutral-600"
        >
          <Icon icon="ArrowRightSIcon" height="20" width="20" />
        </button>
      </div>
    );
  },
);
