import * as React from 'react';
import type { SVGIconProps } from './Icon.types';

const PercentIcon = React.forwardRef<SVGSVGElement, SVGIconProps>(
  ({ color = 'currentColor', filled, ...props }, forwardedRef) => {
    return (
      <svg
        ref={forwardedRef}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>{props.title}</title>
        <path
          fill={color}
          d="M17.5049 21.0029C15.5719 21.0029 14.0049 19.4359 14.0049 17.5029C14.0049 15.5699 15.5719 14.0029 17.5049 14.0029C19.4379 14.0029 21.0049 15.5699 21.0049 17.5029C21.0049 19.4359 19.4379 21.0029 17.5049 21.0029ZM17.5049 19.0029C18.3333 19.0029 19.0049 18.3314 19.0049 17.5029C19.0049 16.6745 18.3333 16.0029 17.5049 16.0029C16.6765 16.0029 16.0049 16.6745 16.0049 17.5029C16.0049 18.3314 16.6765 19.0029 17.5049 19.0029ZM6.50488 10.0029C4.57189 10.0029 3.00488 8.43593 3.00488 6.50293C3.00488 4.56993 4.57189 3.00293 6.50488 3.00293C8.43788 3.00293 10.0049 4.56993 10.0049 6.50293C10.0049 8.43593 8.43788 10.0029 6.50488 10.0029ZM6.50488 8.00293C7.33331 8.00293 8.00488 7.33136 8.00488 6.50293C8.00488 5.6745 7.33331 5.00293 6.50488 5.00293C5.67646 5.00293 5.00488 5.6745 5.00488 6.50293C5.00488 7.33136 5.67646 8.00293 6.50488 8.00293ZM19.076 3.51765L20.4902 4.93186L4.93382 20.4882L3.5196 19.074L19.076 3.51765Z"
        />
      </svg>
    );
  },
);

export default PercentIcon;
