import * as React from 'react';
import type { SVGIconProps } from './Icon.types';

const FlashlightIcon = React.forwardRef<SVGSVGElement, SVGIconProps>(
  ({ color = 'currentColor', filled = false, ...props }, forwardedRef) => {
    return (
      <svg
        ref={forwardedRef}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>{props.title}</title>
        {filled ? (
          <path fill={color} d="M13 10H20L11 23V14H4L13 1V10Z" fillRule="evenodd" clipRule="evenodd" />
        ) : (
          <path
            d="M13 9H21L11 24V15H4L13 0V9ZM11 11V7.22063L7.53238 13H13V17.3944L17.263 11H11Z"
            fill={color}
            fillRule="evenodd"
            clipRule="evenodd"
          />
        )}
      </svg>
    );
  },
);

export default FlashlightIcon;
