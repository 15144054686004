import { forwardRef, useMemo, useRef, useState } from 'react';
import { BaseSelect, Button, Popover, type SelectItem } from '~/components/granular';
import { useElementSize } from '~/hooks/useElementSize';
import type { DropdownSelectProps } from './DropdownSelect.props';

export const DropdownSelect = forwardRef<HTMLDivElement, DropdownSelectProps>(
  (
    {
      appearance = 'filter',
      applyLabel = 'Apply',
      backLabel = 'Back',
      children,
      disabled,
      disableFiltering,
      indeterminateItems,
      items = [],
      label,
      leftIcon,
      loading,
      noDataLabel = 'No data',
      searchPlaceholder,
      selectedItems = [],
      selectedLimit,
      size = 'small',
      trigger,
      width,
      onSelectChange,
    },
    forwardedRef,
  ) => {
    const triggerRef = useRef<HTMLDivElement | null>(null);
    const { width: elementWidth } = useElementSize(triggerRef);

    const [isOpen, setIsOpen] = useState(false);

    function handleToggleOpen(isOpen: boolean) {
      setIsOpen(isOpen);
    }

    function handleSelectChange(selectedItems: Array<SelectItem>) {
      onSelectChange?.(selectedItems);
      setIsOpen(false);
    }

    const popoverTrigger = useMemo(() => {
      if (trigger) return trigger(triggerRef);
      return (
        <div className="tw-inline-flex" ref={triggerRef}>
          <Button appearance={appearance} size={size} leftIcon={leftIcon} rightIcon="ArrowDownSIcon">
            {label}
          </Button>
        </div>
      );
    }, [trigger, appearance, size, leftIcon, label]);

    return (
      <BaseSelect
        ref={forwardedRef}
        applyLabel={applyLabel}
        backLabel={backLabel}
        indeterminateItems={indeterminateItems}
        disableFiltering={disableFiltering}
        items={items}
        loading={loading}
        noDataLabel={noDataLabel}
        searchPlaceholder={searchPlaceholder}
        selectedItems={selectedItems}
        selectedLimit={selectedLimit}
        width={width ?? elementWidth}
        onSelectChange={handleSelectChange}
        toggleOpen={handleToggleOpen}
      >
        <Popover
          align="start"
          direction="bottom"
          trigger={popoverTrigger}
          onTriggerClick={(open) => handleToggleOpen(open)}
          open={isOpen}
          contentWidth={width ?? elementWidth}
          disabled={disabled}
        >
          {children}
        </Popover>
      </BaseSelect>
    );
  },
);
