import * as RadixTabs from '@radix-ui/react-tabs';
import { forwardRef, useState } from 'react';

import { Popover } from '~/components/granular/popover';
import { Separator } from '~/components/granular/separator';
import { classNames } from '~/utils/styles';
import { TabsSkeleton, Tooltip } from '..';
import type { TabContentProps, TabProps, TabsProps } from './Tabs.types';
import { useTabGroup } from './useTabGroup';

export function Tabs({
  className,
  tabs = [],
  staticTabs = [],
  defaultValue,
  value,
  moreTabsLabel,
  children,
  isLoading,
  onValueChange,
}: TabsProps) {
  const [selectedTab, setSelectedTab] = useState<string | undefined>(defaultValue);
  const { tabListRef, visibleTabs, handleSelectedTabChange } = useTabGroup({ tabs, selectedTab });

  function handleTabChange(value: string) {
    setSelectedTab(value);
    handleSelectedTabChange(value);
    if (onValueChange) {
      onValueChange(value);
    }
  }

  if (isLoading) {
    return <TabsSkeleton count={tabs.length} staticItemsCount={staticTabs.length} />;
  }

  return (
    <RadixTabs.Root
      className={classNames('flex flex-col', className)}
      defaultValue={defaultValue}
      value={value}
      onValueChange={handleTabChange}
    >
      <RadixTabs.List
        ref={tabListRef}
        className="flex shrink-0 border-neutral-300 border-b"
        aria-label="Manage your account"
      >
        <>
          <TabList tabs={visibleTabs.filter((tab) => !tab.hidden)} />
          {!!visibleTabs.filter((tab) => tab.hidden).length && (
            <GroupedTabButton dropdownTabs={visibleTabs.filter((tab) => tab.hidden)} label={moreTabsLabel} />
          )}
          {!!staticTabs.length && (
            <>
              <div className="py-2" key={`${value}-separator`}>
                <Separator orientation="vertical" />
              </div>
              <TabList tabs={staticTabs} />
            </>
          )}
        </>
      </RadixTabs.List>
      {children}
    </RadixTabs.Root>
  );
}

const tabClassListDefault =
  'button-100 text-nowrap px-5 h-[40px] items-center justify-center text-[15px] leading-none outline-none';
const tabClassListEnabled =
  'text-neutral-700 hover:text-neutral-1000 data-[state=active]:text-neutral-1000 data-[state=active]:shadow-[inset_0_-1px_0_0,0_1px_0_0] data-[state=active]:shadow-teal-700';

export const TabButton = forwardRef<HTMLButtonElement, TabProps>(
  ({ label, value, disabled, tooltip }, forwardedRef) => {
    const tabClassList = classNames({
      [tabClassListEnabled]: !disabled,
      'text-neutral-500': disabled,
    });
    return (
      <RadixTabs.Trigger ref={forwardedRef} className={tabClassList} value={value} disabled={disabled}>
        <Tooltip trigger={<div className={classNames(tabClassListDefault, 'flex')}>{label}</div>} disabled={!tooltip}>
          {tooltip}
        </Tooltip>
      </RadixTabs.Trigger>
    );
  },
);

export function GroupedTabButton({ dropdownTabs, label }: { dropdownTabs: Array<TabProps>; label: string }) {
  return (
    <Popover
      side="bottom"
      trigger={
        <button type="button" className={classNames(tabClassListDefault, tabClassListEnabled)}>
          {label}
        </button>
      }
    >
      <DropdownTabList tabs={dropdownTabs} />
    </Popover>
  );
}

export function TabList({ tabs }: { tabs: Array<TabProps> }) {
  if (!tabs.length) return null;
  return tabs.map(({ value, ...tabProps }) => <TabButton key={value} value={value} {...tabProps} />);
}

export function DropdownTabList({ tabs }: { tabs: Array<TabProps> }) {
  if (!tabs.length) return null;

  return tabs.map(({ label, value }) => (
    <div key={value} className="flex flex-col px-4 py-3">
      <RadixTabs.Trigger
        value={value}
        className="body-200-light px-2 py-2 text-left hover:bg-neutral-200 data-[state=active]:bg-teal-200"
      >
        {label}
      </RadixTabs.Trigger>
    </div>
  ));
}

export function TabContent({ children, value, className }: TabContentProps) {
  return (
    <RadixTabs.Content className={className} value={value}>
      {children}
    </RadixTabs.Content>
  );
}
