import * as RadixPopover from '@radix-ui/react-popover';
import { forwardRef } from 'react';
import { classNames } from '~/utils/styles';
import type { PopoverProps } from './Popover.types';

export const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  (
    {
      align = 'center',
      children,
      className = '',
      contentWidth,
      direction = 'bottom',
      disabled = false,
      open,
      sideOffset = 5,
      trigger,
      withArrow = false,
      onTriggerClick,
      ...props
    },
    forwardedRef,
  ) => {
    const animation = classNames(
      'relative top-[-1px] will-change-[transform,opacity]',
      'data-[state=open]:data-[side=top]:animate-slideDownAndFade',
      'data-[state=open]:data-[side=right]:animate-slideLeftAndFade',
      'data-[state=open]:data-[side=bottom]:animate-slideUpAndFade',
      'data-[state=open]:data-[side=left]:animate-slideRightAndFade',
    );

    const classes = classNames(
      `position-${direction}-${align} z-20 rounded bg-white shadow-lg focus:outline-none`,
      animation,
      className,
    );

    return (
      <RadixPopover.Root open={open} onOpenChange={onTriggerClick}>
        <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger>
        {!disabled && (
          <RadixPopover.Portal>
            <RadixPopover.Content
              ref={forwardedRef}
              style={{ width: contentWidth }}
              align={align}
              arrowPadding={0}
              side={direction}
              sideOffset={sideOffset}
              className={classes}
              {...props}
            >
              {children}
              {withArrow && <RadixPopover.Arrow data-testid="popover-arrow" />}
            </RadixPopover.Content>
          </RadixPopover.Portal>
        )}
      </RadixPopover.Root>
    );
  },
);
