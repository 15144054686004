import { forwardRef } from 'react';
import { BaseSelect, type SelectItem } from '~/components/granular';
import { CheckboxList } from '../components/checkbox-list';
import type { ListSelectProps } from './ListSelect.props';

export const ListSelect = forwardRef<HTMLDivElement, ListSelectProps>(
  (
    {
      applyLabel = 'Apply',
      backLabel = 'Back',
      disableFiltering,
      indeterminateItems,
      items = [],
      loading,
      noDataLabel = 'No data',
      searchPlaceholder,
      selectedItems = [],
      selectedLimit,
      width,
      onSelectChange,
    },
    forwardedRef,
  ) => {
    function handleSelectChange(selectedItems: Array<SelectItem>) {
      onSelectChange?.(selectedItems);
    }

    return (
      <BaseSelect
        ref={forwardedRef}
        applyLabel={applyLabel}
        backLabel={backLabel}
        indeterminateItems={indeterminateItems}
        disableFiltering={disableFiltering}
        items={items}
        loading={loading}
        noDataLabel={noDataLabel}
        searchPlaceholder={searchPlaceholder}
        selectedItems={selectedItems}
        selectedLimit={selectedLimit}
        width={width}
        onSelectChange={handleSelectChange}
      >
        <CheckboxList />
      </BaseSelect>
    );
  },
);
