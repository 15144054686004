import { Indicator as RadixIndicator, Item as RadixItem } from '@radix-ui/react-radio-group';

import { Label } from '~/components/granular';
import { classNames } from '~/utils/styles';

import type { RadioItem as RadioItemProps } from '../RadioGroup.types';

export const Item = ({ id, label, value, appearance = 'light', disabled }: RadioItemProps) => {
  const classes = classNames(
    'm-0.5 h-4 w-4 cursor-pointer rounded-full border border-neutral-600 border-solid bg-white disabled:border-neutral-400 data-[state=checked]:border-teal-700 disabled:data-[state=checked]:border-neutral-400',
  );
  const indicatorClasses = classNames(
    'flex h-full w-full items-center justify-center after:block after:h-[10px] after:w-[10px] after:rounded-[50%] after:bg-teal-700 data-[disabled]:after:bg-neutral-400',
  );

  return (
    <div className="flex items-center">
      <RadixItem className={classes} disabled={disabled} value={value} id={id}>
        <RadixIndicator className={indicatorClasses} />
      </RadixItem>
      <Label htmlFor={id} appearance={appearance} disabled={disabled}>
        {label}
      </Label>
    </div>
  );
};
