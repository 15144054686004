import { Icon, Tooltip } from '~/components/granular';
import { classNames } from '~/utils/styles';
import type { CellActionsProps } from './CellActions.types';

export function CellActions<T>(params: CellActionsProps<T>) {
  if (!params.actions) {
    return params.value;
  }

  const actionsVisible = params.actions.filter((a) => (a.isHidden ? !a.isHidden(params.data) : true));

  return (
    <div className="flex items-center justify-end gap-4">
      {actionsVisible.map((action) => {
        const icon = typeof action.icon === 'function' ? action.icon(params.data) : action.icon;

        const iconClassName =
          typeof action.iconClassName === 'function' ? action.iconClassName(params.data) : action.iconClassName;

        return (
          <Tooltip
            key={action.id}
            trigger={
              <button
                type="button"
                key={action.id}
                onClick={() => action.onClick(params.data)}
                name={action.tooltipLabel}
                aria-label={action.tooltipLabel}
                disabled={action.isDisabled?.(params.data) || false}
              >
                <Icon
                  className={classNames(
                    'm-t-2',
                    {
                      'text-neutral-400': action.isDisabled?.(params.data),
                      'text-neutral-700 hover:text-teal-700': !action.isDisabled?.(params.data),
                    },
                    iconClassName,
                  )}
                  icon={icon}
                  width={16}
                  height={16}
                />
              </button>
            }
          >
            {action.tooltipLabel}
          </Tooltip>
        );
      })}
    </div>
  );
}

CellActions.displayName = 'CellActions';
