import * as React from 'react';
import type { SVGIconProps } from './Icon.types';

const SubtractIcon = React.forwardRef<SVGSVGElement, SVGIconProps>(
  ({ color = 'currentColor', filled, ...props }, forwardedRef) => {
    return (
      <svg
        ref={forwardedRef}
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        {...props}
      >
        <title>{props.title}</title>
        <path fillRule="evenodd" clipRule="evenodd" d="M12 8H2V6H12V8Z" fill={color} />
      </svg>
    );
  },
);

export default SubtractIcon;
