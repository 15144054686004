import { useIntl } from 'react-intl';
import { Checkbox, Input, useBaseSelectContext } from '~/components/granular';

export function SearchInput({
  isChecked = false,
  itemsCount,
  withSelectAll = false,
  onSelectAll,
}: { withSelectAll?: boolean; onSelectAll?: () => void; isChecked?: boolean; itemsCount?: number }) {
  const { formatMessage } = useIntl();
  const { searchTerm, searchPlaceholder, handleSearchChange, loading } = useBaseSelectContext();

  return (
    <div className="w-full border-neutral-200 border-b px-4 py-3">
      <Input
        height="small"
        placeholder={searchPlaceholder}
        value={searchTerm}
        leftIcon="SearchIcon"
        onChange={handleSearchChange}
        autoComplete="off"
        role="searchbox"
      />

      {!loading && withSelectAll && (
        <Checkbox
          id="select-all"
          className="overflow-hidden whitespace-nowrap pt-3"
          name="select-all"
          checked={isChecked}
          onClick={onSelectAll}
          label={formatMessage({ id: 'common.selectAll' }, { count: itemsCount })}
        />
      )}
    </div>
  );
}
