import { cva } from 'class-variance-authority';
import { forwardRef } from 'react';
import { Icon } from '~/components/granular';
import { classNames } from '~/utils/styles';
import type { ButtonProps } from './Button.types';

const buttonVariants = cva(
  'flex cursor-pointer items-center justify-between whitespace-nowrap disabled:cursor-not-allowed disabled:bg-neutral-200 disabled:text-neutral-400',
  {
    variants: {
      appearance: {
        default:
          'bg-teal-700 px-4 py-3 text-white hover:bg-teal-800 focus:bg-teal-800 focus:outline focus:outline-teal-800 focus:outline-offset-2 active:bg-teal-900',
        secondary:
          'border border-neutral-300 border-solid bg-white px-4 py-3 hover:border-teal-500 hover:bg-teal-100 hover:text-teal-700 focus:border-teal-500 focus:bg-teal-200 focus:text-teal-700 focus:outline focus:outline-teal-500 focus:outline-offset-2 active:border-teal-500 active:bg-teal-200 active:text-teal-700 disabled:border-none',
        ghost:
          'bg-transparent px-4 py-3 hover:bg-neutral-200 focus:bg-neutral-300 focus:outline focus:outline-neutral-300 focus:outline-offset-2 active:bg-neutral-300',
        danger:
          'bg-red-700 px-4 py-3 text-white hover:bg-red-800 focus:bg-red-800 focus:outline focus:outline-red-800 focus:outline-offset-2 active:bg-red-900',
        text: 'px-0 py-0 text-black hover:text-teal-700 disabled:bg-transparent disabled:hover:bg-transparent',
        filter:
          'cursor-pointer rounded border border-neutral-400 px-2 py-2 text-sm leading-4 hover:border-teal-700 hover:text-teal-700 disabled:border-neutral-300 disabled:border-solid disabled:hover:border-neutral-300',
        input:
          'w-full cursor-pointer border border-neutral-400 px-2 py-2 text-sm leading-4 hover:border-teal-700 hover:text-teal-700 disabled:border-neutral-300 disabled:border-solid disabled:hover:border-neutral-300',
      },
      size: {
        small: 'h-8',
        medium: 'h-9',
        large: 'h-10',
      },
    },
    compoundVariants: [
      {
        appearance: ['default', 'secondary', 'ghost', 'danger', 'text'],
        size: 'small',
        class: 'button-200',
      },
      {
        appearance: ['default', 'secondary', 'ghost', 'danger', 'text'],
        size: ['medium', 'large'],
        class: 'button-100',
      },
      {
        appearance: ['filter', 'input'],
        size: ['small', 'medium', 'large'],
        class: 'body-200-light',
      },
    ],
    defaultVariants: {
      appearance: 'default',
      size: 'medium',
    },
  },
);

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { appearance = 'default', className, children, leftIcon, rightIcon, disabled = false, size = 'large', ...props },
    forwardedRef,
  ) => {
    const classes = classNames(buttonVariants({ appearance, size }), className);

    return (
      <button ref={forwardedRef} type="button" disabled={disabled} className={classes} {...props}>
        {leftIcon && <Icon icon={leftIcon} className="inline-block pr-2" />}
        {children}
        {rightIcon && <Icon icon={rightIcon} className="inline-block pl-2" />}
      </button>
    );
  },
);
