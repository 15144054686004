import { classNames } from '~/utils/styles';

import { Icon } from '~/components/granular';
import type { CardProps } from './Card.types';

export function Card({ className, children, isLink = false, ...props }: CardProps) {
  const classes = classNames('rounded-[4px] border border-neutral-300 bg-white', {
    'cursor-pointer flex-row hover:mx-[-1px] hover:my-[-1px] hover:border-2 hover:border-teal-700 [&_.link-icon]:hover:text-teal-700':
      isLink,
    'cursor-default': !isLink,
  });
  return (
    <div className={classes}>
      <div className={classNames('flex w-full items-center justify-between', className)} {...props}>
        {children}
        {isLink && <Icon icon="ArrowRightIcon" data-testid="ArrowRightIcon" className="link-icon text-neutral-600" />}
      </div>
    </div>
  );
}
