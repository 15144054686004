import { createContext, useContext } from 'react';

export function contextFactory<T>(defaultValue: T = undefined as T) {
  const context = createContext<T>(defaultValue);

  function useCtx() {
    const ctx = useContext(context);

    if (!ctx) {
      throw new Error('useCtx must be used within a Provider');
    }

    return ctx;
  }

  return [context, useCtx] as const;
}
