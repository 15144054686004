import { Avatar, Icon } from '~/components/granular';

export function LogoOrIcon({ logo, isCollapsed }: { logo: string | undefined; isCollapsed: boolean }) {
  return logo ? (
    <Avatar size={isCollapsed ? 'small' : 'medium'} data-testid="logo-container">
      <img className="'object-contain'" src={logo} alt="logo" />
    </Avatar>
  ) : (
    <Avatar size="small">
      <Icon className="h-full w-full bg-gradient-500 p-2" color="white" icon="BuildingIcon" data-testid="icon" />
    </Avatar>
  );
}
