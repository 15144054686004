import { useBaseSelectContext } from '~/components/granular';
import { Button } from '~/components/granular/button';

interface BackButtonProps {
  visible: boolean;
  onClick: () => void;
}

export function BackButton({ visible, onClick }: BackButtonProps) {
  const { backLabel } = useBaseSelectContext();

  if (!visible) return null;

  return (
    <div className="border-neutral-200 border-b px-4 py-2">
      <Button appearance="text" size="small" leftIcon="ArrowLeftSIcon" onClick={onClick}>
        {backLabel}
      </Button>
    </div>
  );
}
