import { useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';
import type { TabProps } from './Tabs.types';

// Helper function to calculate the width of all tabs
const getTabsWidth = (tabListRef: React.RefObject<HTMLDivElement>) => {
  if (!tabListRef.current) return 0;
  return Array.from(tabListRef.current.children).reduce(
    (acc, child) => acc + (child as HTMLElement).getBoundingClientRect().width,
    0,
  );
};

// Helper function to create a hidden element
const createHiddenElement = (textContent: string, classList: Array<string>) => {
  const fakeElement = document.createElement('div');
  Object.assign(fakeElement.style, {
    display: 'block',
    visibility: 'hidden',
    position: 'absolute',
    zIndex: '-1',
    pointerEvents: 'none',
  });
  fakeElement.textContent = textContent;
  fakeElement.classList.add(...classList);
  return fakeElement;
};

export function useTabGroup({ tabs, selectedTab }: { tabs: Array<TabProps>; selectedTab?: string }) {
  const tabListRef = useRef<HTMLDivElement | null>(null);
  const initialTabWidth = useRef<number | null>(null);
  const { width: windowSize } = useWindowSize({ debounceDelay: 200 });

  const [visibleTabs, setVisibleTabs] = useState<Array<TabProps>>(tabs);

  useEffect(() => {
    setVisibleTabs(tabs);
  }, [tabs]);

  const handleTabOverflow = (activeTabIndex: number) => {
    const hiddenTabsLength = visibleTabs.filter((tab) => tab.hidden).length;
    const visibleTabsLength = visibleTabs.length - hiddenTabsLength;
    const nextTabToHide = visibleTabs.findLast((tab, index) => !tab.hidden && index !== activeTabIndex);

    const newVisibleTabs = visibleTabs.map((tab, index) => {
      if (index === activeTabIndex) return tab;
      if (nextTabToHide && nextTabToHide.value === tab.value && visibleTabsLength > 1)
        return {
          ...tab,
          hidden: true,
        };
      return tab;
    });
    setVisibleTabs(newVisibleTabs);
  };

  const handleTabUnderflow = (firstHiddenTab: TabProps) => {
    const newVisibleTabs = visibleTabs.map((tab) => {
      if (tab.value === firstHiddenTab.value) {
        return {
          ...tab,
          hidden: false,
        };
      }
      return tab;
    });
    setVisibleTabs(newVisibleTabs);
  };

  useEffect(() => {
    if (windowSize === null || tabListRef.current === null) return;

    if (initialTabWidth.current === null) {
      const tabWidth = getTabsWidth(tabListRef);
      if (tabWidth) initialTabWidth.current = tabWidth;
    }

    if (initialTabWidth.current === null) return;

    const visibleTabsCount = visibleTabs.filter((tab) => !tab.hidden).length;

    const tabListWidth = tabListRef.current.getBoundingClientRect().width;
    const tabsWidth = getTabsWidth(tabListRef);
    const activeTabIndex = tabs.findIndex((tab) => tab.value === selectedTab);

    if (initialTabWidth.current > tabListWidth && visibleTabsCount > 1) {
      if (tabsWidth > tabListWidth) {
        handleTabOverflow(activeTabIndex);
        return;
      }
    }

    const firstHiddenTab = visibleTabs.find((tab) => tab.hidden);
    if (firstHiddenTab) {
      const fakeTab = createHiddenElement(firstHiddenTab.label, [
        'button-100',
        'text-nowrap',
        'px-5',
        'h-[40px]',
        'text-[15px]',
        'leading-none',
      ]);
      document.body.appendChild(fakeTab);
      const fakeTabWidth = fakeTab.getBoundingClientRect().width;
      document.body.removeChild(fakeTab);

      if (tabsWidth + fakeTabWidth < tabListWidth) {
        handleTabUnderflow(firstHiddenTab);
      }
    }
    // TODO: fix the below dependency issue
    // biome-ignore lint/correctness/useExhaustiveDependencies: handleTabOverflow and handleTabUnderflow change each render
  }, [tabs, selectedTab, visibleTabs, windowSize, handleTabOverflow, handleTabUnderflow]);

  const handleSelectedTabChange = (t: string) => {
    const newVisibleTabs = visibleTabs.map((tab) => {
      if (tab.value === t)
        return {
          ...tab,
          hidden: false,
        };
      return tab;
    });
    setVisibleTabs(newVisibleTabs);
  };

  return { tabListRef, visibleTabs, handleSelectedTabChange };
}
