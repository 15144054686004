import type { IconType } from '~/components/icons';
import { classNames } from '~/utils/styles';
import type { AlertBarIconProps } from '.';
import { Icon } from '../..';

export function AlertBarIcon({ appearance = 'info', className, size = 'large', icon }: AlertBarIconProps) {
  const classes = classNames(
    {
      'text-teal-700': appearance === 'info',
      'text-green-700': appearance === 'success',
      'text-yellow-700': appearance === 'warning',
      'text-red-700': appearance === 'error',
    },
    className,
  );
  const sizePx = size === 'small' ? 20 : 24;

  const icons: { [key: string]: IconType } = {
    info: 'InformationIcon',
    success: 'CheckboxCircleIcon',
    warning: 'WarningIcon',
    error: 'ErrorWarningIcon',
  };

  return <Icon icon={icon || icons[appearance]} width={sizePx} height={sizePx} className={classes} role="img" />;
}
