import * as React from 'react';
import type { SVGIconProps } from './Icon.types';

const TimeLineIcon = React.forwardRef<SVGSVGElement, SVGIconProps>(
  ({ color = 'currentColor', filled, ...props }, forwardedRef) => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <title>{props.title}</title>
        <path
          d="M10.0003 18.3337C5.39783 18.3337 1.66699 14.6028 1.66699 10.0003C1.66699 5.39783 5.39783 1.66699 10.0003 1.66699C14.6028 1.66699 18.3337 5.39783 18.3337 10.0003C18.3337 14.6028 14.6028 18.3337 10.0003 18.3337ZM10.0003 16.667C11.7684 16.667 13.4641 15.9646 14.7144 14.7144C15.9646 13.4641 16.667 11.7684 16.667 10.0003C16.667 8.23222 15.9646 6.53652 14.7144 5.28628C13.4641 4.03604 11.7684 3.33366 10.0003 3.33366C8.23222 3.33366 6.53652 4.03604 5.28628 5.28628C4.03604 6.53652 3.33366 8.23222 3.33366 10.0003C3.33366 11.7684 4.03604 13.4641 5.28628 14.7144C6.53652 15.9646 8.23222 16.667 10.0003 16.667ZM10.8337 10.0003H14.167V11.667H9.16699V5.83366H10.8337V10.0003Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default TimeLineIcon;
