import * as React from 'react';
import type { SVGIconProps } from './Icon.types';

const CheckIcon = React.forwardRef<SVGSVGElement, SVGIconProps>(
  ({ color = 'currentColor', filled, ...props }, forwardedRef) => {
    return (
      <svg
        ref={forwardedRef}
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        {...props}
      >
        <title>{props.title}</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2071 4.35355L5.5 11.0607L1.79289 7.35355L3.20711 5.93934L5.5 8.23223L10.7929 2.93934L12.2071 4.35355Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default CheckIcon;
