import { forwardRef } from 'react';

import { classNames } from '~/utils/styles';

import type { HeadingProps } from './Heading.types';

export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ as = 'h1', className, children, ...props }, forwardedRef) => {
    const classes = classNames(
      {
        'title-100': as === 'h1',
        'title-200': as === 'h2',
        'heading-200-heavy': as === 'h3',
        'body-100-heavy': as === 'h4',
      },
      className,
    );
    const Element = as;

    return (
      <Element ref={forwardedRef} className={classes} {...props}>
        {children}
      </Element>
    );
  },
);
