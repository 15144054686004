import * as RadixSelect from '@radix-ui/react-select';

import { Button, Divider } from '~/components/granular';
import type { DropdownActionsProps } from './DropdownActions.types';

export function DropdownActions({
  applyBtnText,
  clearBtnText,
  hideApplyButton,
  hideClearButton,
  handleApplyButtonClick,
  handleClearAllButtonClick,
}: DropdownActionsProps) {
  return (
    <RadixSelect.Group className="w-full">
      {/* hide whole block only if both buttons have to be hidden */}
      {!(hideApplyButton && hideClearButton) && (
        <>
          <Divider className="w-full" />

          <div className="flex w-full flex-column justify-between px-2 py-2">
            {!hideClearButton && (
              <Button
                appearance="ghost"
                size="small"
                className="!justify-center mx-2 w-full"
                onClick={handleClearAllButtonClick}
              >
                {clearBtnText}
              </Button>
            )}

            <Button
              appearance="default"
              size="small"
              className="!justify-center mx-2 w-full"
              onClick={handleApplyButtonClick}
            >
              {applyBtnText}
            </Button>
          </div>
        </>
      )}
    </RadixSelect.Group>
  );
}
