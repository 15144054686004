import * as RadixSeparator from '@radix-ui/react-separator';
import { classNames } from '~/utils/styles';

import type { SeparatorProps } from './Separator.types';

export function Separator({ orientation, decorative, className }: SeparatorProps) {
  const classes = classNames(
    'bg-neutral-300 data-[orientation=horizontal]:h-px data-[orientation=vertical]:h-full data-[orientation=horizontal]:w-full data-[orientation=vertical]:w-px',
    className,
  );
  return <RadixSeparator.Root orientation={orientation} decorative={decorative} className={classes} />;
}
