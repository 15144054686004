import { forwardRef } from 'react';

import { classNames } from '~/utils/styles';
import type { AvatarProps } from './Avatar.types';

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ size = 'medium', children, noBorder, rounded, className, ...props }, forwardedRef) => {
    const classes = classNames(
      'flex items-center justify-center overflow-hidden',
      {
        'h-4 w-4': size === 'x-small',
        'h-8 w-8': size === 'small',
        'h-12 w-12': size === 'medium',
        'h-[96px] w-[96px]': size === 'large',
        'border-0': noBorder,
        'border border-neutral-300': !noBorder,
        'rounded-[4px]': !rounded,
        'rounded-full': rounded,
      },
      className,
    );

    return (
      <div ref={forwardedRef} className={classes} {...props}>
        {children}
      </div>
    );
  },
);
