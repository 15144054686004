import { forwardRef } from 'react';
import { classNames } from '~/utils/styles';

import type { TextareaProps } from './Textarea.types';

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ id, disabled = false, placeholder, className, hasError, size = 'large', ...props }, forwardedRef) => {
    const classes = classNames(
      'body-200-light w-full border border-solid px-[9px] pt-2 text-neutral-1000 placeholder:text-neutral-600',
      'focus:outline focus:outline-1 disabled:cursor-not-allowed disabled:border-neutral-300 disabled:bg-neutral-200 disabled:text-neutral-600',
      {
        'border-neutral-400 hover:border-teal-800 focus:border-teal-800 focus:outline-teal-800': !hasError,
        'border-red-800 hover:border-red-800 focus:border-red-800 focus:outline-red-800': hasError,
      },
      {
        'h-[72px]': size === 'small',
        'h-[112px]': size === 'large',
      },
      className,
    );

    return (
      <textarea
        ref={forwardedRef}
        id={id}
        disabled={disabled}
        placeholder={placeholder}
        className={classes}
        {...props}
      />
    );
  },
);
