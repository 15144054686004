import { micromark } from 'micromark';
import { classNames } from '~/utils/styles';

import type { MarkdownProps } from './Markdown.types';

export const Markdown = ({ className, markdown }: MarkdownProps) => (
  <div
    // biome-ignore lint/security/noDangerouslySetInnerHtml: This is a markdown component and it's expected to render HTML
    dangerouslySetInnerHTML={{ __html: micromark(markdown) }}
    className={classNames('[&>p>br]:mb-4 [&>p]:mb-2 [&>ul]:mb-4 [&>ul]:list-disc [&>ul]:pl-6', className)}
  />
);
