import * as RadixLabel from '@radix-ui/react-label';

import { forwardRef } from 'react';
import { classNames } from '~/utils/styles';

import type { LabelProps } from './Label.types';

export const Label = forwardRef<HTMLLabelElement, LabelProps>(
  ({ id, children, disabled, appearance = 'normal', position = 'right', className, ...props }, forwardedRef) => {
    const classes = classNames(
      'body-200 cursor-pointer text-neutral-1000',
      {
        'text-neutral-500': disabled,
        'font-normal': appearance === 'light',
        'font-semibold': appearance === 'bold',
        'ml-2': position === 'right',
        'mr-2': position === 'left',
        'mb-2 block': position === 'top',
      },
      className,
    );

    return (
      <RadixLabel.Root ref={forwardedRef} className={classes} {...props}>
        {children}
      </RadixLabel.Root>
    );
  },
);
