import { type RefObject, useState } from 'react';
import { useDebounceCallback, useResizeObserver } from 'usehooks-ts';

type Size = {
  width?: number;
  height?: number;
};

export function useElementSize(ref: RefObject<HTMLElement>, delay = 200) {
  const [{ width, height }, setSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });

  const onResize = useDebounceCallback(setSize, delay);

  useResizeObserver({
    ref: ref,
    onResize,
    box: 'border-box',
  });

  return { width, height };
}
