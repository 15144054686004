import * as React from 'react';
import type { SVGIconProps } from './Icon.types';

const WarningIcon = React.forwardRef<SVGSVGElement, SVGIconProps>(
  ({ color = 'currentColor', filled, ...props }, forwardedRef) => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <title>{props.title}</title>
        <path
          d="M2.7248 21C2.34147 21 2.05814 20.8333 1.8748 20.5C1.69147 20.1667 1.69147 19.8333 1.8748 19.5L11.1248 3.5C11.3081 3.16667 11.5998 3 11.9998 3C12.3998 3 12.6915 3.16667 12.8748 3.5L22.1248 19.5C22.3081 19.8333 22.3081 20.1667 22.1248 20.5C21.9415 20.8333 21.6581 21 21.2748 21H2.7248ZM11.9998 10C11.7165 10 11.4791 10.0957 11.2878 10.287C11.0958 10.479 10.9998 10.7167 10.9998 11V14C10.9998 14.2833 11.0958 14.5207 11.2878 14.712C11.4791 14.904 11.7165 15 11.9998 15C12.2831 15 12.5208 14.904 12.7128 14.712C12.9041 14.5207 12.9998 14.2833 12.9998 14V11C12.9998 10.7167 12.9041 10.479 12.7128 10.287C12.5208 10.0957 12.2831 10 11.9998 10ZM11.9998 18C12.2831 18 12.5208 17.904 12.7128 17.712C12.9041 17.5207 12.9998 17.2833 12.9998 17C12.9998 16.7167 12.9041 16.4793 12.7128 16.288C12.5208 16.096 12.2831 16 11.9998 16C11.7165 16 11.4791 16.096 11.2878 16.288C11.0958 16.4793 10.9998 16.7167 10.9998 17C10.9998 17.2833 11.0958 17.5207 11.2878 17.712C11.4791 17.904 11.7165 18 11.9998 18ZM4.4498 19H19.5498L11.9998 6L4.4498 19Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default WarningIcon;
